export const getTranslations = t => {
  return {
    myListPageTitle: t("kf.mylist.page.title"),
    createList: t("kf.saveToList.create.header"),
    tablTitle: t("kf.account.label.lists"),
    tablTitleMedia: t("kf.account.label.media"),
    listPageEmptyText: t("kf.myList.page.emptyTxt"),
    bestSellers: t("kf.myList.page.bestSellers"),
    sortBy: t("kf.favorite.label.sort"),
    edit: t("kf.account.label.edit"),
    deleteList: t("kf.myList.page.deletList"),
    moveItems: t("kf.myList.page.details.moveItems"),
    deleteItems: t("kf.myList.page.details.deleteItems"),
    itemPrice: t("kf.list.table.itemPrice"),
    quantity: t("kf.list.table.cartQuantity"),
    total: t("kf.list.table.total"),
    item: t("kf.list.table.item"),
    listTotal: t("kf.myList.page.details.listTotal"),
    browseMoreProducts: t("kf.myList.page.details.browseMoreProducts"),
    selectAll: t("kf.list.button.selectAll"),
    created: t("kf.myList.page.details.create.label"),
    backToTop: t("kf.plp.label.backToTop"),
    editList: t("kf.myList.page.details.editList"),
    viewList: t("kf.findstore.label.viewList"),
    shoupOurBestSeller: t("kf.myList.page.ourBestSellers"),
    shoupOurBestSellerListBtn: t("kf.myList.page.ourBestSellers.listPage"),
  }
}

